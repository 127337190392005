import React, { useState, useEffect, useRef } from "react"
import Header from "components/header/Header"
import MenuItems from "components/common/MenuItems"
import HomePage from "routes/HomePage"
import { Route, Routes } from "react-router-dom"
import Greetings from "components/section/introduce/Greetings"
import Footer from "components/common/Footer"
import Copyright from "components/common/Copyright"
import Contents from "components/section/Contents"

function App() {
  return (
    <div className="wrap">
      <Header data={MenuItems} />
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:section/:subSection" element={<Contents />} />
          {/* <Route path="/introduce/greetings" element={<Greetings />} /> */}
        </Routes>
      </main>
      <Footer />
      <Copyright />
    </div>
  )
}

export default App
