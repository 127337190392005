import React from "react";

const Copyright = () => {
  return (
    <div id="Copyright">
      Copyright 2022. ORTHOBiT. All Rights Reserved.
    </div>
  );
};

export default Copyright;
