import React from "react"
import { Link } from "react-router-dom"

const NavCreator = (props) => {
  const MenuItems = props
  return (
    <ul id="navigator-menu" className="ml-20 ">
      {MenuItems.data.map((ItemDepth1) => {
        return (
          <ul className="ItemDepth1" key={ItemDepth1.idx}>
            <Link to={{ pathname: ItemDepth1.link }}>{ItemDepth1.title}</Link>
            <ul className="ItemDepth2-ul" key={ItemDepth1.idx}>
              <div>
                {ItemDepth1.items.map((ItemDepth2) => {
                  if (ItemDepth2.link === "/user/navigate") {
                    return (
                      // <a href="http://125.133.97.209:3000" key={ItemDepth2.idx}>
                      <a
                        href="https://orthobit.net:3000"
                        key={ItemDepth2.idx}
                      >
                        {ItemDepth2.title}
                      </a>
                    )
                  } else {
                    return (
                      <li className="ItemDepth2" key={ItemDepth2.idx}>
                        <Link
                          to={{
                            pathname: ItemDepth2.link,
                          }}
                        >
                          {ItemDepth2.title}
                        </Link>
                      </li>
                    )
                  }
                })}
              </div>
            </ul>
          </ul>
        )
      })}
    </ul>
  )
}

export default NavCreator
