import React from "react"

const Contact = () => {
  return (
    <p>
      Welcome to AllOnFourImplant!
      <div>Hello!</div>
    </p>
  )
}

export default Contact
