import React from "react"
import "assets/css/ContentsStyle.css"
import { useParams } from "react-router-dom"
import VideoContainer from "VideoContainer"
import video from "../../dental_video.mp4"
import SectionMenu from "./SectionMenu"

const Contents = () => {
  const { section, subSection } = useParams()

  return (
    <div id="Contents">
      <div id="Visual">
        <div id="OpacityBlack">
          <div id="PlaceHolder">
            <span>{section}</span>
            <br />
            {subSection}
          </div>
          <VideoContainer video={video} />
        </div>
      </div>
      <SectionMenu section={section} subSection={subSection} />
      <div id="Context">
        개발중인 페이지입니다.
      </div>
    </div>
  )
}

export default Contents
