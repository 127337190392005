import React from "react"
import NavigatorTopBar from "components/header/NavigatorTopBar"
import Navigator from "components/header/Navigator"

const Header = (props) => {
  const MenuItems = props
  return (
    <header>
      <Navigator data={MenuItems.data} />
    </header>
  )
}
export default Header
