import React from "react"
import { Link } from "react-router-dom"

const SS = (section, subSection) => {
  switch (section) {
    case "company":
      return (
        <tbody>
          <tr>
            <td className={`${subSection === "회사 소개" ? "td_Active" : ""}`}>
              <Link to="/company/회사 소개">
                <div className="full">회사 소개</div>
              </Link>
            </td>
            <td
              className={`${subSection === "대표 인사말씀" ? "td_Active" : ""}`}
            >
              <Link className="full" to="/company/대표 인사말씀">
                <div className="full">대표 인사말씀</div>
              </Link>
            </td>
            <td
              className={`${subSection === "찾아오시는 길" ? "td_Active" : ""}`}
            >
              <Link className="full" to="/company/찾아오시는 길">
                <div className="full">찾아오시는 길</div>
              </Link>
            </td>
          </tr>
        </tbody>
      )
    case "service":
      return (
        <tbody>
          <tr>
            <td className={`${subSection === "D.A." ? "td_Active" : ""}`}>
              <Link className="full" to="/service/D.A.">
                <div className="full">D.A.</div>
              </Link>
            </td>
            {/* <td>대표 인사말씀</td>
            <td>찾아오시는 길</td> */}
          </tr>
        </tbody>
      )
    case "customer":
      return (
        <tbody>
          <tr>
            <td
              className={`${subSection === "자주하는 질문" ? "td_Active" : ""}`}
            >
              <Link className="full" to="/customer/자주하는 질문">
                <div className="full">자주하는 질문</div>
              </Link>
            </td>
            <td
              className={`${subSection === "고객의 소리" ? "td_Active" : ""}`}
            >
              <Link className="full" to="/customer/고객의 소리">
                <div className="full">고객의 소리</div>
              </Link>
            </td>
          </tr>
        </tbody>
      )
  }
}

const SectionMenu = (props) => {
  const section = props.section
  const subSection = props.subSection
  return (
    <div
      id="SectionMenu"
      className="relative z-20 mx-auto w-[1200px] h-[50px] top-[-51px]"
    >
      <table
        border="0"
        cellPadding="0"
        cellSpacing="0"
        align="center"
        className="w-full mx-auto bg-white"
      >
        {SS(section, subSection)}
        {/* {Depths.depth1.items.map((TdItem) => {
              return (
                <td
                  className={`w-[25%] border-solid text-center leading-[50px] border-r-[1px] border-[color:#ccc] text-[color:#333] hover:bg-[color:#f4f4f4] hover:text-[color:#494b66] active:bg-[#e6e6e6] ${
                    TdItem.idx === Depths.depth2.idx
                      ? "bg-[color:#494b66] text-[#fff] font-bold pointer-events-auto hover:bg-[color:#494b66] hover:text-[color:#fff] active:bg-[color:#37394d]"
                      : ""
                  }`}
                  key={TdItem.idx}
                >
                  <Link to={{ pathname: TdItem.link }}>
                    <div className="w-full h-full">{TdItem.title}</div>
                  </Link>
                </td>
              )
            })} */}
      </table>
    </div>
  )
}

export default SectionMenu
