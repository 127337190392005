import IntroduceComponents from "components/section/introduce"
import ServiceComponents from "components/section/service"
import UserComponents from "components/section/user"
import CustomerComponents from "components/section/customer"

const MenuItems = [
  {
    idx: 0,
    title: "회사 소개",
    link: "/company/회사 소개",
    import: IntroduceComponents.Greetings,
    depth: 1,
    items: [
      {
        idx: 0,
        title: "회사 소개",
        link: "/company/회사 소개",
        import: IntroduceComponents.Company,
        depth: 2,
      },
      {
        idx: 1,
        title: "대표 인사말씀",
        link: "/company/대표 인사말씀",
        import: IntroduceComponents.Greetings,
        depth: 2,
      },
      {
        idx: 2,
        title: "찾아오시는 길",
        link: "/company/찾아오시는 길",
        import: IntroduceComponents.Contact,
        depth: 2,
      },
    ],
  },
  {
    idx: 1,
    title: "서비스 소개",
    link: "/service/D.A.",
    import: ServiceComponents.DirectAligner,
    depth: 1,
    items: [
      {
        idx: 0,
        title: "D.A.",
        link: "/service/D.A.",
        import: ServiceComponents.DirectAligner,
        depth: 2,
      },
    ],
  },
  {
    idx: 2,
    title: "회원 공간",
    link: "/user/navigate",
    import: UserComponents.Navigate,
    depth: 1,
    items: [
      {
        idx: 0,
        title: "로그인",
        link: "/user/navigate",
        import: UserComponents.Navigate,
        depth: 2,
      },
    ],
  },
  {
    idx: 3,
    title: "고객 센터",
    link: "/customer/자주하는 질문",
    import: CustomerComponents.QnA,
    depth: 1,
    items: [
      {
        idx: 0,
        title: "자주하는 질문",
        link: "/customer/자주하는 질문",
        import: CustomerComponents.QnA,
        depth: 2,
      },
      {
        idx: 1,
        title: "고객의 소리",
        link: "/customer/고객의 소리",
        import: CustomerComponents.Question,
        depth: 2,
      },
    ],
  },
]

export { MenuItems as default }
