import React from "react"
import { Link } from "react-router-dom"
import NavCreator from "components/header/NavCreator"
import OrthobitLogo from "assets/images/orthobit_logo_nobackground.png"

const Navigator = (props) => {
  const MenuItems = props
  return (
    <div id="navigator">
      <Link to="/">
        <div id="navigator-logobox">
          <img src={OrthobitLogo} id="navigator-logo" />
        </div>
      </Link>
      <div id="navigator-inner">{NavCreator(MenuItems)}</div>
    </div>
  )
}
export default Navigator
