import { useState, useEffect, useRef } from "react"

import { Player } from "App"

import React from "react"
import Top from "components/header/Top"
import Header from "components/header/Header"
import Section from "components/common/Section"
import Footer from "components/common/Footer"
import Copyright from "components/common/Copyright"
import MenuItems from "components/common/MenuItems"
import Dots from "../Dots"

import useVideoPlayer from "../hooks/useVideoPlayer"
import VideoContainer from "../VideoContainer"
import video from "aligner_making.mp4"

const DIVIDER_HEIGHT = 5

function HomePage() {
  const outerDivRef = useRef()
  const [scrollIndex, setScrollIndex] = useState(1)
  const userExpScrollVal = 200
  useEffect(() => {
    const wheelHandler = (e) => {
      e.preventDefault()
      const { deltaY } = e
      const { scrollTop } = outerDivRef.current // 스크롤 위쪽 끝부분 위치
      const pageHeight = window.innerHeight // 화면 세로길이, 100vh와 같습니다.

      // console.log("deltaY", deltaY)
      // console.log("scrollTop", scrollTop);
      // console.log("scrolIndex", scrollIndex);
      // console.log("pageHeight", pageHeight);
      // console.log("VAL", (scrollTop + userExpScrollVal) / (pageHeight + DIVIDER_HEIGHT));

      outerDivRef.current.scrollTo({
        top:
          pageHeight *
            (parseInt(
              (scrollTop + userExpScrollVal) / (pageHeight + DIVIDER_HEIGHT)
            ) +
              deltaY / 100) +
          DIVIDER_HEIGHT *
            (parseInt(
              (scrollTop + userExpScrollVal) / (pageHeight + DIVIDER_HEIGHT)
            ) +
              deltaY / 100),
        left: 0,
        behavior: "smooth",
      })
      setScrollIndex(
        parseInt((scrollTop + userExpScrollVal) / pageHeight) + deltaY / 100
      )
    }
    const outerDivRefCurrent = outerDivRef.current
    outerDivRefCurrent.addEventListener("wheel", wheelHandler)
    return () => {
      outerDivRefCurrent.removeEventListener("wheel", wheelHandler)
    }
  }, [])
  return (
    <div ref={outerDivRef} className="MainPage">
      <Dots scrollIndex={scrollIndex} />
      <VideoContainer video={video} />
      <div className="divider"></div>
      <div className="inner bg-yellow">1</div>
      <div className="divider"></div>
      <div className="inner bg-blue">2</div>
      <div className="divider"></div>
      <div className="inner bg-blue">3</div>
      <div className="divider"></div>
      <div className="inner bg-blue">4</div>
      <div className="divider"></div>
      <div className="inner bg-blue">5</div>
      <div className="divider"></div>
      <div className="inner bg-blue">6</div>
      <div className="divider"></div>
      <div className="inner bg-blue">7</div>
      <div className="divider"></div>
      <div className="inner bg-blue">8</div>
      <div className="divider"></div>
      <div className="inner bg-blue">9</div>
    </div>
  )
}

export default HomePage
