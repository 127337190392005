import React from "react"
// import BottomLogo from "assets/images/ecclesia_Logo_Wight_Medium.jpg"
import BottomLogo from "assets/images/orthobit_logo_wb_nobackground.png"

const Footer = () => {
  return (
    <footer>
      <div id="BottomBrief">
        <div id="BottomBriefTop">
          <div id="BottomLogoBox">
            <img id="BottomLogo" src={BottomLogo} />
          </div>
        </div>
        <div id="BottomBriefBottom">
          <span>상호명 : (주)올소비트</span>
          <span>|</span>
          <span>대표이사 : 이노범</span>
          <span>|</span>
          <span>사업자등록번호 : 796-86-01633</span>
          <br />
          <span>주소 : 광주광역시 서구 상무공원로 7, 지노빌딩 1층 104호 (치평동)</span>
          <br />
          <span>Tel : 062-385-5302</span>
          <span>|</span>
          <span>Fax : 062-385-5303</span>
          <span>|</span>
          <span>E-mail : orthobit@orthobit.net</span>
          <br />
        </div>
      </div>
    </footer>
  )
}

export default Footer
