import React from "react"

const Greetings = () => {
  console.log("Greetings")
  return (
    <div>
      <p>aaa</p>
    </div>
  )
}

export default Greetings
